import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Logo from "../components/Logo"
import Contact from "../components/Contact"
import CaseHero from "../components/CaseHero"
import CaseBody from "../components/CaseBody"
import CaseLinks from "../components/CaseLinks"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query($path: String!) {
    caseStudy: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        color
        path
        thumb
        title
        excerpt
        role
        technology
        url
      }
    }
    work: allMarkdownRemark(filter: { frontmatter: { type: { eq: "work" } } }) {
      nodes {
        frontmatter {
          title
          path
          order
        }
      }
    }
  }
`

export default function Template({ data }) {
  const { caseStudy, work } = data
  const { frontmatter, html } = caseStudy
  const sortedWork = work.nodes.sort((a, b) =>
    a.frontmatter.order < b.frontmatter.order ? -1 : 1
  )
  return (
    <Layout>
      <SEO
        title={`${frontmatter.title} | Case Study | Jens Fischer`}
        description={frontmatter.excerpt}
      />
      <main>
        <Logo />
        <CaseHero html={html} frontmatter={frontmatter} />
        <CaseBody html={html} frontmatter={frontmatter} />
        <CaseLinks current={caseStudy} works={sortedWork} />
        <Contact />
      </main>
    </Layout>
  )
}
