import React from "react"
import styled from "styled-components"
import css from "@styled-system/css"
import PropTypes from "prop-types"
import Text from "./Text"
import { container } from "../theme/config"

const Wrapper = styled.div`
  ${container}
  display: flex;
  justify-content: center;
  padding-bottom: 150px;
  padding-bottom: 10vh;

  &:before {
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    background: #f8f8f8;
    top: 200px;
    left: -100%;
    width: 300%;
    bottom: 0px;
    transform: rotate(5deg);
  }

  & > * {
    z-index: 2;
  }

  p {
    white-space: pre-wrap;
    font-weight: 400;
    margin-bottom: 30px;
  }

  p:first-child {
    font-weight: 400;
  }

  a {
    color: #528cb4;
    text-decoration: none;
    position: relative;
    &:before {
      position: absolute;
      display: inline-table;
      height: 1px;
      width: 100%;
      content: "";
      background: #528cb4;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) scale(0);
      transition: transform 0.2s ease-out;
    }
    &:hover {
      text-decoration: none;
      &:before {
        transform: translateX(-50%) scale(1);
      }
    }
  }

  h2,
  h3 {
    ${({ theme }) =>
      css({
        fontSize: theme.typeStyles.subhead.fontSize,
        color: theme.colors.medium,
      })}
    margin-top: 20px;
    margin-bottom: 30px;
  }

  img {
    max-width: 100%;
    margin-top: 120px;
  }

  .gatsby-resp-iframe-wrapper {
    max-width: 100%;
    margin-bottom: 60px;
  }
`

const CaseBody = ({ html }) => (
  <Text
    as={Wrapper}
    typeStyle="body"
    color="medium"
    dangerouslySetInnerHTML={{ __html: html }}
  />
)

CaseBody.propTypes = {
  html: PropTypes.string.isRequired,
}

export default CaseBody
