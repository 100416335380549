import React from "react"
import { string, node } from "prop-types"
import styled from "styled-components"
import Text from "./Text"

const AnchorStyled = styled.a`
  display: inline-block;
  padding: 14px 28px;
  font-weight: 700;
  text-decoration: none;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;

  ${({ theme }) => `
    color: ${theme.colors.dark};
    border-color: ${theme.colors.dark};
  `}

  &:hover {
    ${({ color }) => `
      color: ${color};
      border-color: ${color};
    `}
  }
`

const Button = ({ url, children, color }) => (
  <Text
    as={AnchorStyled}
    typeStyle="body"
    href={url}
    target="_blank"
    rel="noopener noreferrer nofollow"
    color={color}
  >
    {children}
  </Text>
)

Button.propTypes = {
  url: string.isRequired,
  children: node.isRequired,
  color: string.isRequired,
}

export default Button
