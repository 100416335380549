import React from "react"
import styled from "styled-components"
import { arrayOf, shape, string } from "prop-types"
import { Link } from "gatsby"
import Text from "./Text"
import { container } from "../theme/config"

const Wrapper = styled.div`
  ${container}
  margin-top: 100px;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }

  a,
  span {
    margin-right: 20px;
  }

  span {
    font-weight: 700;
    color: black;
  }

  a {
    text-decoration: none;
    position: relative;
    display: inline-block;
    margin-bottom: 3px;
    &:before {
      position: absolute;
      display: inline-table;
      height: 1px;
      width: 100%;
      content: "";
      background: #528cb4;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) scale(0);
      transition: transform 0.2s ease-out;
    }
    &:hover {
      text-decoration: none;
      &:before {
        transform: translateX(-50%) scale(1);
      }
    }
  }
`

const CaseLinks = ({ works, current }) => (
  <Wrapper>
    <div>
      <Text to="/" typeStyle="body" color="blue" as={Link}>
        Home
      </Text>
    </div>
    {works.map(work => {
      if (work.frontmatter.path === current.frontmatter.path) {
        return (
          <div key={`caselink-${work.frontmatter.path}`}>
            <Text typeStyle="body" color="dark" as="span">
              {work.frontmatter.title}
            </Text>
          </div>
        )
      }
      return (
        <div key={`caselink-${work.frontmatter.path}`}>
          <Text
            typeStyle="body"
            color="blue"
            as={Link}
            to={work.frontmatter.path}
          >
            {work.frontmatter.title}
          </Text>
        </div>
      )
    })}
  </Wrapper>
)

CaseLinks.propTypes = {
  works: arrayOf(
    shape({
      title: string,
      path: string,
    })
  ).isRequired,
  current: shape({
    path: string,
  }).isRequired,
}

export default CaseLinks
