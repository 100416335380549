import React from "react"
import { string, shape } from "prop-types"
import styled from "styled-components"
import css from "@styled-system/css"

import Button from "./Button"
import Text from "./Text"
import { container } from "../theme/config"

const Wrapper = styled.div`
  ${container}

  h1, p {
    padding-bottom: 30px;
  }

  p {
    letter-spacing: 0;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;

    span {
      font-weight: 700;
      color: ${props => props.theme.colors.dark};
    }
  }

  ul {
    padding-bottom: 50px;
  }
`
const CaseHero = ({ frontmatter }) => (
  <Wrapper>
    <Text as="h1" typeStyle="heading" color="dark">
      {frontmatter.title}
    </Text>
    <Text as="p" typeStyle="body" color="medium">
      {frontmatter.excerpt}
    </Text>
    <ul>
      <Text as="li" typeStyle="body" color="medium">
        <span>Role: </span>
        {frontmatter.role}
      </Text>
      <Text as="li" typeStyle="body" color="medium">
        <span>Technology: </span>
        {frontmatter.technology}
      </Text>
    </ul>
    <div>
      <Button url={frontmatter.url} color={frontmatter.color}>
        Launch
      </Button>
    </div>
  </Wrapper>
)

CaseHero.propTypes = {
  frontmatter: shape({
    title: string,
    excerpt: string,
    role: string,
    technology: string,
    url: string,
  }).isRequired,
}

export default CaseHero
